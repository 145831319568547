<template>
  <Modal
    v-model="showModalManage"
    class-name="vertical-center-modal"
    footer-hide
    @on-cancel="cancelHandle"
    :width="width"
  >
    <p
      slot="header"
      style="font-size: 18px; font-weight: bold; color: #333333"
      class="modalHeader"
    >
      {{ type == "add" ? "保存模板" : "我的模板" }}
    </p>
    <div class="modalContent">
      <div class="leftContent">
        <div class="title">模板列表</div>
        <!-- <div class="add"
             @click="type='add'">
          新模板
          <Icon type="ios-add-circle-outline"
                size="20" />
        </div> -->
        <div class="item" v-for="(item, index) in modalList" :key="index">
          <span @click="chooseModal(item)">
            {{ item.templateName }}
          </span>
          <Icon
            type="md-trash"
            class="delBtn"
            @click="delModal(item, index)"
            size="20"
          />
        </div>
      </div>

      <div class="rightContent">
        <div class="title">基本信息</div>
        <Form :model="formItem" :label-width="80">
          <FormItem label="模板名称">
            <Input
              v-model="formItem.name"
              placeholder="请输入模板名称"
              :disabled="isAdd"
              style="width: 181px"
            />
          </FormItem>
          <FormItem label="模板描述">
            <Input
              v-model="formItem.describe"
              size="large"
              type="textarea"
              maxlength="100"
              show-word-limit
              :rows="4"
              placeholder="请输入模板描述文字"
              style="width: 371px"
            />
          </FormItem>
        </Form>
        <div class="bottomBtn" style="text-align: center; margin-top: 26px">
          <Button
            size="small"
            style="margin-right: 18px; width: 52px"
            @click="cancelHandle"
            type="error"
            >取消</Button
          >
          <Button
            size="small"
            @click="changeModal"
            style="width: 52px"
            type="info"
            >{{ type == "add" ? "添加" : "修改" }}</Button
          >
          <Button
            size="small"
            v-if="type != 'add'"
            @click="useModal"
            style="margin-left: 18px; width: 52px"
            type="info"
            >使用</Button
          >
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { remove, save, getList } from "../../api/modalManage";

export default {
  name: "modalManage",
  props: {
    width: {
      type: Number,
      default: 712,
    },
    saveType: {
      type: Number,
    },
    chooseTemplate: Function,
  },
  computed: {
    showModalManage: {
      get() {
        return this.$store.state.modal.showModalManage;
      },
      set(newValue) {
        this.$store.state.modal.showModalManage = newValue;
      },
    },
    modalList: {
      get() {
        return this.$store.state.modalList;
      },
      set(newValue) {
        this.$store.state.modalList = newValue;
      },
    },
    saveSearchData: {
      get() {
        return this.$store.state.saveSearchData;
      },
      set(newValue) {
        this.$store.state.saveSearchData = newValue;
      },
    },
  },
  watch: {
    type: function (newValue) {
      if (newValue == "add") {
        this.isAdd = false;
        this.formItem = {
          name: "",
          describe: "",
          activeId: "",
        };
      } else {
        this.isAdd = true;
      }
    },
    showModalManage: function (newValue) {
      if (newValue) {
        if (this.saveSearchData != null) {
          this.type = "add";
        } else {
          this.type = "replace";
        }
        getList({
          current: this.page,
          size: 100,
          type: this.saveType,
        }).then((data) => {
          this.modalList = data.records;
        });
      } else {
        this.saveSearchData = null;
      }
    },
  },
  data() {
    return {
      formItem: {
        name: "",
        describe: "",
        activeId: "",
      },
      type: "",
      page: 1,
      isAdd: false,
    };
  },
  methods: {
    delModal(item, index) {
      this.$Modal.confirm({
        title: "删除",
        content: "<p>确定要删除这个模板吗</p>",
        onOk: () => {
          remove({
            id: item.id,
          }).then(() => {
            this.$msg.success({ text: "删除成功" });

            this.modalList.splice(index, 1);
          });
        },
        onCancel: () => {
          // this.$msg.success("", "已取消删除");
          // this.$Message.info('已取消删除');
        },
      });
    },
    chooseModal(item) {
      // this.$emit("chooseTemplate",item);
      // this.showModalManage = false;
      this.formItem = {
        name: item.templateName,
        describe: item.templateRemark,
        activeId: item.id,
        templateParams: item.templateParams,
      };
      this.type = "replace";
    },
    changeModal() {
      const data = {
        templateName: this.formItem.name,
        templateRemark: this.formItem.describe,
        templateParams: this.saveSearchData,
        type: this.saveType,
      };
      let _name = this.modalList.find((item) => {
        return item.templateName === this.formItem.name;
      });

      if (this.type == "add" && _name) {
        this.$msg.error({ text: "模板名称重复 ，请重新输入" });

        return;
      }

      if (!this.formItem.name) {
        this.$msg.error({ text: "请输入模板名称" });

        return;
      }
      this.type == "replace" &&
        Object.assign(data, {
          id: this.formItem.activeId,
          // templateParams: this.formItem.templateParams
        });
      save(data).then(() => {
        this.type == "replace"
          ? this.$msg.success({ text: "修改成功" })
          : this.$msg.success({ text: "添加成功" });
        this.cancelHandle();
      });
    },
    useModal() {
      this.$msg.success({ text: "模板引用成功" });
      this.formItem.useModel = true;
      this.$emit("chooseTemplate", this.formItem);
      this.showModalManage = false;
    },
    cancelHandle() {
      this.showModalManage = false;
      this.formItem = {
        name: "",
        describe: "",
        activeId: "",
      };
      this.saveSearchData = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
.modalHeader {
  position: relative;
  text-indent: 10px;
  &::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 20px;
    background: rgba(0, 182, 255, 1);
    top: 0;
    left: 0;
  }
}
.modalContent {
  width: 675px;
  height: 244px;
  border: 1px solid #e0e8ed;
  border-radius: 1px;
  display: flex;
  .leftContent {
    width: 212px;
    height: 100%;
    overflow: auto;
    background: #f8fafb;
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 36px;
      text-indent: 13px;
    }
    .add,
    .item {
      display: flex;
      height: 30px;
      width: calc(100% - 26px);
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      span:hover {
        color: #00b6ff;
      }
      .delBtn:hover {
        color: #00b6ff;
      }
    }
    .add {
      font-weight: bold;
      &:hover {
        color: #00b6ff;
      }
    }
  }
  .rightContent {
    flex: 1;
    height: 100%;
    .ivu-form-item {
      margin-bottom: 7px;
    }
    .title {
      height: 36px;
      border-bottom: 1px solid #e6e6e6;
      line-height: 36px;
      text-indent: 13px;
      margin-bottom: 15px;
    }
  }
}
</style>

import { post, get } from '@/utils/http'

export function getList (params) {
  return post({ url: '/service-insight/query_template/list', params })
}
export function remove (params) {
  return get({ url: '/service-insight/query_template/remove', params })
}
export function save (params) {
  return post({ url: '/service-insight/query_template/save', params })
}
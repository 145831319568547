export default {
	data() {
		return {
		}
	},
	computed: {
		// 已选条件
		selectList() {
			let arr = [];
			let formItem = this.nowFormItem || this.formItem;
			this.selectionOption.forEach(option => {
				let item = formItem[option.key];
				if(item || item === 0) {
					let {type , name , key} = option;
					// 如果是选项列表 要找到对应名字
					if(type == 'option') {
						this.optionList[key].some(obj => {
							if(item == obj.id || item == obj.areaId) {
								item = obj.name;
								return true;
							}
						})
					} else if(type == 'list') {
						let list = this.optionList[key];
						let res = item.map(val => {
							let str = "";
							list.some(obj => {
								if(val == obj.id || val == obj.areaId) {
									str = obj.name;
									return true;
								}
							})
							return str;
						});
						item = res;
					}
					arr.push({
						...option,
						value : item
					})
				}
			})
			return arr;
		}
	},
	mounted() { },
	methods: {
		// 通过id获取名字
		formalName(type,id) {
			let list = this[type] || this.cityData[type];
			let str = "";

			list.map(item => {
				if(item.id == id) {
					str = item.name || item.title;
				}
			})
			return str;
		},
		// 转化两位小数点 保留整数
		toFixed(val) {
			return val ? Number( (+val).toFixed(2) ) : "";
		},
		checkboxGroupChange(item,key,data) {
			item[key] = data.map(item => {
				return item.id;
			})
		},
		// 输入范围的值
		changeRangeVal(val, type, index, item = this.nowFormItem || this.formItem) {
			this.$set(item[type], index, val);
			// this.formItem[type][index] = val;
		},

		//删除已选条件
		delSingle(obj) {
			let item = this.nowFormItem || this.formItem;
			let key = obj.key;
			// let option = this.selectionOption[obj.key];
			if(obj.type == "string" || obj.type == "option") {
				item[key] = "";
			} else if(obj.type == "range") {
				item[`${key}Start`] = "";
				item[`${key}End`] = "";
				item[key] = [];
			} else if(obj.type == "list") {
				item[key] = [];
			}
		},

		// 全选checkbox用
		handleCheckAll (val, list, key) {
			let item = this.nowFormItem || this.formItem;
			// 已经全选
			if (this[val]) {
				item[key] = [];
			} else {
				item[key] = [];
				item[key] = list.map(item => {
					return item.id;
				});
			}
			this[val] = !this[val]
		},
	}
}
<template>
  <Modal v-model="showDevManage"
        class-name="vertical-center-modal"
        footer-hide
        :width="width">
    <p slot="header"
       style="font-size: 18px;font-weight: bold;color: #333333;"
       class="modalHeader">
        开发商
    </p>
    <div class="modalContent">
        <div class="part">
            <div class="part-title">选择企业</div>

			<div class="areaTransfer company">
				<div class="leftTransfer">
					<div class="leftTransferSearch">
						<Input v-model="formItem.searchCity"
							placeholder="输入关键字搜索"
							@on-change="searchCity"/>
						<span class="btn-all" @click="mutliSelectAll">全选</span>
					</div>

					<div class="leftTransferBottom">
						<div class="leftRadio">
							<RadioGroup v-model="formItem.multiCityRadio"
								@on-change="getMultiChoose">
								<Radio v-for="(item,index) in enterprise" :key="index" :label="index">{{item.name}}</Radio>
							</RadioGroup>
						</div>
						<div class="rightBox">
							<div class="item"
								v-for="(item,index) in formItem.multiCityList"
								@click="chooseCity(item)"
								:key="index">
								{{item.name}}
							</div>
						</div>
					</div>
				</div>

				<Icon type="md-arrow-round-forward" class="centerBtn" />

				<div class="rightTransfer">
					<div class="topBtn">
						已选
						<span @click="clearChooseCity">清空</span>
					</div>
					<div class="itemBox">
						<Tag closable
							class="item"
							color="#00B6FF"
							v-for="(item,index) in formItem.chooseCityList"
							:key="index"
							@on-close="delCompany(item,index)">
							{{item.name}}</Tag>
					</div>
				</div>
			</div>

			<div class="cityManage">
				<div class="manageBtn" @click="cityModalSwitch">
					<Icon type="ios-add-circle-outline"
							size="20" />
					<div>企业群管理</div>
				</div>
				<CheckboxGroup v-model="formItem.cityManage" class="rightMangeChoose">
					<Checkbox label="twitter">
						<span>Twitter</span>
					</Checkbox>
					<Checkbox label="facebook">
						<span>Facebook</span>
					</Checkbox>
					<Checkbox label="github">
						<span>Github</span>
					</Checkbox>
					<Checkbox label="snapchat">
						<span>Snapchat</span>
					</Checkbox>
					<Checkbox label="twitter">
						<span>Twitter</span>
					</Checkbox>
					<Checkbox label="facebook">
						<span>Facebook</span>
					</Checkbox>
					<Checkbox label="github">
						<span>Github</span>
					</Checkbox>
					<Checkbox label="snapchat">
						<span>Snapchat</span>
					</Checkbox>
				</CheckboxGroup>
			</div>
        </div>
    </div>
	<div class="modal-bottom">
		<div class="btn" @click="cancel">取消</div>
		<div class="btn add" @click="add">添加</div>
	</div>
  </Modal>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { getEnterprise } from '../../api/public'
export default {
    name: 'devManage',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 820
		},
		chooseCityList : Array,
    },
    data () {
        return {
            cityList : [],
            regionList : [],

            formItem: {
                province : "",
                city : "",
				region : "",
				searchCity : "",
				multiCityList : [],
				chooseCityList : [],

                sellWay : [],
                landUse : [],
            },
        }
    },
    computed : {
        ...mapState([
			"enterprise",
		]),
		checkSellAll() {
			return this.formItem.sellWay.length == this.sellWay.length;
		},
		checkLandAll() {
			return this.formItem.landUse.length == this.landUse.length;
		},
        showDevManage: {
            get () {
                return this.$store.state.modal.showDevManage;
            },
            set (newValue) {
                this.$store.state.modal.showDevManage = newValue;
            }
        },
	},
	watch : {
		showDevManage : function(val) {
			if(val) {
				this.formItem.chooseCityList = JSON.parse(JSON.stringify(this.chooseCityList));
			}
		}
	},
    methods: {
		cityModalSwitch() {

		},
		getMultiChoose(key) {
			let list = this.enterprise[key];
			this.formItem.multiCityList = list.list.map(city =>{
				return {
					id : city.id,
					name : city.gsmc
				}
			});
		},
		searchCity() {
			let formItem = this.formItem;
			let val = formItem.searchCity;
			if(!val) return;

			getEnterprise({
				q : val
			}).then(info => {
				let arr = [];
				formItem.multiCityRadio = "";
				info.forEach(list => {
					list.list.forEach(item => {
						arr.push({
							id : item.id,
							name : item.gsmc
						})
					})
				});
				formItem.multiCityList = arr;
			})
		},
		chooseCity(item) {
			if(item.checked) return;
			item.checked = true;
			this.formItem.chooseCityList.push(item);
		},
		delCity(item,index) {
			item.checked = false;
			this.formItem.chooseCityList.splice(index, 1);
		},
		clearChooseCity() {
			let item = this.formItem;
			item.chooseCityList.forEach(item => {
				item.checked = false;
			});
			item.chooseCityList = [];
		},
		mutliSelectAll() {
			this.formItem.multiCityList.forEach(item => {
				this.chooseCity(item);
			})
		},

		add() {
			this.$emit("add",this.formItem.chooseCityList);
			this.cancel();
		},
		cancel() {
			this.showDevManage = false;
		}
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/common.scss';

.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    .ivu-modal {
        top: 0;
    }
    .ivu-modal-header {
        border: none;
    }
}
.modalHeader {
    position: relative;
    text-indent: 10px;
    &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background: rgba(0, 182, 255, 1);
        top: 0;
        left: 0;
    }
}
.modalContent {
    @include flex;
    flex-direction: column;
    // padding: 20px 20px 17px;
    font-size: 14px;
    .ivu-checkbox-group {
        display: inline-block;
    }
    .part {
        position: relative;
        width: 100%;
        padding: 25px 0 28px 28px;
        border-radius: 1px;
        border: 1px solid #E0E8ED;
        &-title {
            position: absolute;
            top: -10px;
            left: 28px;
            color: #333;
            font-weight: bold;
            background-color: #fff;
        }
        .line {
            @include flex(flex-start);
            .title {
                color: #666;
            }
            .des {
                .ivu-select {
                    margin-right: 10px;
                }
            }
        }
        &.top {
            .line {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

	.areaTransfer {
		display: flex;
		align-items: center;
		margin-left: 12px;
		.leftTransfer,
		.rightTransfer {
			width: 287px;
			height: 207px;
			border: 1px solid rgba(190, 199, 219, 1);
			border-radius: 5px;
		}
		.leftTransfer {
			background: rgba(246, 249, 255, 0.6);
			padding: 8px;
			box-sizing: border-box;
			.leftTransferBottom {
				display: flex;
			}
			.leftRadio {
				flex: 1;
				.ivu-radio-group {
					display: block;
				}
				.ivu-radio-wrapper {
					display: block;
					margin-bottom: 5px;
					color: #333333;
				}
			}
			.rightBox {
				width: 150px;
				height: 156px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(228, 228, 228, 1);
				border-radius: 5px;
				padding: 8px 0;
				box-sizing: border-box;
				overflow: auto;

				.item {
					width: 100%;
					font-size: 16px;
					text-indent: 10px;
					white-space: nowrap;
					cursor: pointer;
					color: #333333;
					.active {
					color: #4373e8;
					}
					&:hover {
					color: #4373e8;
					}
				}
			}
		}
		.leftTransferSearch {
			@include flex;
			margin-bottom: 5px;
			.ivu-input-wrapper {
				width: 220px;
			}
			.btn-all {
				margin-left: auto;
				margin-right: 5px;
				cursor: pointer;
			}
		}
		.centerBtn {
			margin: 0 14px;
			cursor: pointer;
		}
		.rightTransfer {
			border: 1px solid rgba(228, 228, 228, 1);
			overflow: hidden;
			.topBtn {
				width: 100%;
				height: 36px;
				background: #f6f9ff;
				display: flex;
				padding: 0 15px;
				box-sizing: border-box;
				align-items: center;
				justify-content: space-between;
				span {
					color: #999999;
					cursor: pointer;
					&:hover {
						color: #333;
					}
				}
			}
			.itemBox {
				width: 100%;
				padding: 0 15px;
				box-sizing: border-box;
				overflow: auto;
				height: 170px;
				.ivu-tag {
				background: #ededed !important;
				}
				.ivu-tag-checked {
				background: #00b6ff !important;
				}
			}
		}

		&.company {
			.ivu-radio-wrapper {
				display: block;
				// margin-bottom: 5px;
			}
		}
	}

	.cityManage {
		display: flex;
		.manageBtn {
			text-align: center;
			margin: 10px 0 0 5px;
			cursor: pointer;
			&:hover {
			color: #00b6ff;
			}
		}
		.rightMangeChoose {
			margin: 10px 0 0 28px;
			width: 388px;
			display: grid;
			grid-template-columns: repeat(4, auto);
			grid-column-gap: 24px;
			white-space: nowrap;
		}
	}
}
.modal-bottom {
	@include flex;
	margin: 15px auto;
	.btn {
		@include flex;
		width:53px;
		height:26px;
		margin-right: 20px;
		background:rgba(237,237,237,1);
		border-radius:5px;
		color: #666;
		font-size: 14px;
		cursor: pointer;
		&.add {
			color: #fff;
			background-color: #00B6FF;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
